<template>
  <div>
<nav id="nav" class="fixed-top navbar navbar-dark bg-light navbar-expand-md">
    <a href="#" class="navbar-brand">HeatMapFX</a>
    <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar01">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="navbar01" data-toggle="collapse" data-target=".nav-collapse">
        <ul class="navbar-nav">
          <li class="nav-item">
          <router-link class="nav-link active" to="/">News</router-link> 
                   </li>
          <li class="nav-item">
                    <router-link class="nav-link active" to="/heatmap">Heatmap</router-link>
          </li> 
          <!-- <li class="nav-item">
          <router-link class="nav-link" to="/oob">Open Order Book</router-link>
          </li> -->
          <li class="nav-item">
          <router-link class="nav-link active" to="/usdoob">Open Order Book</router-link> 
          </li>
          <!-- <router-link to="/chart">Chart</router-link> |  -->
          <li class="nav-item">
          <router-link class="nav-link active" to="/chartPlot">Levels</router-link> 
          </li>
           <li class="nav-item">
          <router-link class="nav-link active" to="/hunt">Stop Loss Hunter</router-link> 
          </li>
          <li class="nav-item">
          <router-link class="nav-link active" to="/about">About</router-link> 
          </li>
          </ul>
        <div class="navbar-collapse collapse justify-content-end" id="navbar01" data-toggle="collapse" data-target=".nav-collapse">
      <ul class="navbar-nav">
          <li v-if="!auth.authenticated" class="nav-item">
            <a class="btn btn-light nav-link" v-on:click="auth.loginWithRedirect()" :disabled="auth.authenticated">Login</a>
          </li>
          <li v-if="auth.authenticated" class="nav-item">
            <a class="btn btn-light nav-link" v-on:click="auth.logout({returnTo:this.$returnToUrl})" :disabled="!auth.authenticated">Logout</a>
          </li>
          <li v-if="auth.authenticated" class="nav-item">
            <div class="nav-link active"> Welcome! {{auth.user?.nickname}}</div>
          </li>
        </ul>
    </div>
    </div>
    
</nav>
  
      <router-view/>
      <footerfile/>
  </div>
</template>

<script>


import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap.bundle.js";

import footerfile from "./components/footerfile.vue"
export default {
  inject: ['auth'],
  components: {
    footerfile
  }
 }
</script>

<style lang="scss">
@import './assets/styles/custom.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #eef0f1;
  margin-top: 60px;
  // font-family: $font;
}

.nav-link{
  color: #fff;
}
.navbar-brand {
  color: #fff;
}

</style>