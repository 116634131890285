<template>
<div>
 <div v-if="!auth.authenticated && authstate.loading === false" class="container"> 
          <!-- <div> {{authstate.authenticated}}  </div> -->
          <div> <p>Login with your Discord Credentials to view the "HeatMapFX News"</p> </div>
          <div> <p>If you are a member of the T-Bone discord server or a Paid Member, login for basic access</p> </div>
          <div class="btn btn-dark btn-lg active" v-on:click="auth.loginWithRedirect()" :disabled="auth.authenticated">Login</div>
          <hr>
        <!-- <div> Auth Level? : {{authLevel}} </div> -->
        <div > <p>If you are NOT a member of T-Bone start your trial here:</p></div>
        <div>
          <a href="https://launchpass.com/heatmapfx/paid-members" class="btn btn-secondary btn-lg active" role="button">
          Start your HeatMapFx Paid Trial Here</a>
        </div>
        <hr>
 </div>
<div v-if="auth.authenticated && authLevel === 0 || authLevel === 1 || authLevel === 2 || authLevel === 3 || authLevel === 4" class="row">
  <!-- <div class="col">{{filterednews}}</div> -->
  <!-- <div> Auth Level? : {{authLevel}} </div> -->
  <div class="col">
   <table class="table table-hover caption-top"> 
     <caption class="text-center">News for {{format_date(browserDate)}}</caption>
     <thead>
            <tr>
                <th>Date</th>
                <th>Time</th>
                <th>CUR</th>
                <th>Impact</th>
                <th>News</th>
            </tr>
     </thead>
     <tbody>
            <tr v-for="item in info" v-bind:key="item.index">
                    <td>{{format_dateOnly(item.date)}}</td>
                    <td>{{format_dateTimeOnly(item.date)}}</td> 
                    <td>{{item.country}}</td>
                    <td  v-bind:class = "(item.impact == 'High')?'newsRed':(item.impact == 'Medium')?'newsOrange': 'newsYellow'">{{item.impact}}</td>
                    <td>{{item.title}}</td>
              </tr>
            </tbody>
        </table>
  </div>
  <div class="col"></div>
</div>
</div>
</template>
<script>
import axios from "axios";
import { AuthenticationState } from 'vue-auth0-plugin';
// import moment from "moment-timezone";
import { format , utcToZonedTime } from "date-fns-tz";
// import { parseISO } from "date-fns";
export default ({
  info : "news",
  el: '#app',
  inject: ['auth'],
  data () {
    return {
      info: null,
      browserDate: null,
      ibetatesters: null,
      ispecialtesters: null,
      itboneUsers: null,
      ipaidMembers: null,
      ipremiumMembers: null,
      authstate: AuthenticationState,
      isinbeta: false,
      isinspecial: false,
      isinTbone: false,
      isinpaid: false,
      isinprem: false
    }
  },
  methods: {
    news: function(){
      axios.get(this.$apihostname+'/News')
      .then(response => response.data)
      .then(data => {
        this.info = data
        // console.log(data)
        // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
        // let tzc = Intl.DateTimeFormat().resolvedOptions().timeZone
        // console.log(data[6].date+"Z")
        // console.log(tzc)
        // console.log("parsed time " + parseISO(data[6].date),{timeZone: 'UTC'})
        // console.log((utcToZonedTime(parseISO(data[6].date+"Z"),tzc)))
        // console.log(format(utcToZonedTime(data[6].date+"Z",tzc),'MM/dd/yyyy, h:mm a z'))
        // console.log(moment.tz(moment.tz.guess(true)))
        // console.log(data)
        // console.log(data[0].date)
        // console.log(moment.utc(data[0].date).tz(moment.tz.guess(true)).format('MM/DD/YYYY, h:mmaz'))
      })
    },
    bdate: function(){
      // this.browserDate = moment.utc()
      this.browserDate = new Date()
    },
    betatesters: function(){
      axios.get(this.$apihostname+'/np/betatesters')
      .then(response => response.data)
      .then(data => {
        this.ibetatesters = data
        this.isinbeta = data.includes(this.auth.user?.sub.slice(15,33))
        })
      },
    specialtesters: function(){
      axios.get(this.$apihostname+'/np/specialtesting')
      .then(response => response.data)
      .then(data => {
        this.ispecialtesters = data
        this.isinspecial = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    paidMembers: function(){    /// Paid Basic Members
      axios.get(this.$apihostname+'/np/paidMembers')
      .then(response => response.data)
      .then(data => {
        this.ipaidMembers = data
        this.isinpaid = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    premiumMembers: function(){    /// Premium Members
      axios.get(this.$apihostname+'/np/premiumMembers')
      .then(response => response.data)
      .then(data => {
        this.ipremiumMembers = data
        this.isinprem = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    tboneUsers: function(){    /// tbone users
      axios.get(this.$apihostname+'/np/tboneUsers')
      .then(response => response.data)
      .then(data => {
        this.itboneUsers = data
        this.isinTbone = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    format_date(value){
         if (value) {
          //  return moment.utc(value).tz(moment.tz.guess(true)).format('MM/DD/YYYY, h:mmaz')
          return format(utcToZonedTime(value,Intl.DateTimeFormat().resolvedOptions().timeZone),'MM/dd/yyyy, h:mm a z')
          }
      },
      format_dateOnly(value){
         if (value) {
          //  return moment.utc(value).tz(moment.tz.guess(true)).format('MM/DD/YYYY')
          return format(utcToZonedTime(value+"Z",Intl.DateTimeFormat().resolvedOptions().timeZone),'MM/dd/yyyy')
          }
      },
    format_dateLong(value){
         if (value) {
          //  return moment.utc(value).tz(moment.tz.guess(true)).format('ddd MMMM Do YYYY, h:mm a z')
          return format(utcToZonedTime(value+"Z",Intl.DateTimeFormat().resolvedOptions().timeZone),'MMMM Do YYYY, h:mm a z')
          }
      },
    format_dateTimeOnly(value){
         if (value) {
          //  return moment.utc(value).tz(moment.tz.guess(true)).format('h:mmaz')
          return format(utcToZonedTime(value+"Z",Intl.DateTimeFormat().resolvedOptions().timeZone),'h:mmaz')
          }
      }
  },
  created(){
    this.news()
    this.bdate()
    this.betatesters()
    this.specialtesters()
    this.paidMembers()
    this.premiumMembers()
    this.tboneUsers()
  },
    mounted(){
    this.interval = setInterval(() => {
        this.news()
        this.bdate()
        this.betatesters()
        this.specialtesters()
        this.paidMembers()
        this.premiumMembers()
        this.tboneUsers()
    }, 30000 );
  },
  computed: {
        authLevel: function() {
          //  const authp = injectAuth();
            if(
               this.isinspecial === true                
               ){
             return 4
               }
            else if(
               this.isinbeta === true
               ){
              return 3
            }
            else if(
               this.isinprem === true        
               ){
              return 2
            }
            else if(
               this.isinpaid  === true      
               ){
              return 1
            }
            else if(this.isinTbone === true || this.isinpaid === true){
              return 0
            }
            else return 5
        }
   }
  
})
</script>
<style scoped>
.table td {
text-align: left
}
.newsRed{
  background: red;
}
.newsOrange{
  background: orange;
}
.newsYellow{
  background: yellow;
}
</style>